/*-----------Types of FROMATION actions--------*/

/*---SAVE----*/
export const COMMUNICATION_SAVE_SUCCESS = "COMMUNICATION_SAVE_SUCCESS";
export const COMMUNICATION_SAVE_FAILED = "COMMUNICATION_SAVE_FAILED";
export const COMMUNICATION_IS_SAVING = "COMMUNICATION_IS_SAVING";
/*---FETCH----*/
export const COMMUNICATION_FETCH_SUCCESS = "COMMUNICATION_FETCH_SUCCESS";
export const COMMUNICATION_FETCH_FAILED = "COMMUNICATION_FETCH_FAILED";
export const COMMUNICATION_IS_FETCHING = "COMMUNICATION_IS_FETCHING";

/*---UPDATE----*/
export const COMMUNICATION_UPDATE_SUCCESS = "COMMUNICATION_UPDATE_SUCCESS";
export const COMMUNICATION_UPDATE_FAILED = "COMMUNICATION_UPDATE_FAILED";
export const COMMUNICATION_IS_UPDATING = "COMMUNICATION_IS_UPDATING";

/*---PATCH----*/
export const COMMUNICATION_PATCH_SUCCESS = "COMMUNICATION_PATCH_SUCCESS";
export const COMMUNICATION_PATCH_FAILED = "COMMUNICATION_PATCH_FAILED";
export const COMMUNICATION_IS_PATCHING = "COMMUNICATION_IS_PATCHING";


/*---DELETE----*/
export const COMMUNICATION_DELETE_SUCCESS = "COMMUNICATION_DELETE_SUCCESS";
export const COMMUNICATION_DELETE_FAILED = "COMMUNICATION_DELETE_FAILED";
export const COMMUNICATION_IS_DELETING = "COMMUNICATION_IS_DELETING";