import axios from "axios";

export const apiSaveUser = user => {
  return axios.post("/users/create", user);
};


//Read user
export const apiGetUsers = month => {
  const prefix = "/users";
  const url = month ? `${prefix}/${month}` : prefix;
  return axios.get(url);
};

//Get user by email
export const apiGetUserByEmail = email => {
  const prefix = "/user";
  const url = `${prefix}/${email}`;
  return axios.get(url);
};
//Get user by id
export const apiGetUserById = id => {
  const prefix = "/users";
  const url = `${prefix}/${id}`;
  return axios.get(url);
};

//Update user
export const apiUpdateUser = (id, user) => {
  const prefix = `/users/${id}`;
  return axios.put(prefix, user);
};

//Update user Password
export const apiUpdateUserPwd = data => {
  return axios.put("/changeUserPwd", data);
};

//Destroy user
export const apiDeleteUser = id => {
  const prefix = `/users/${id}`;
  return axios.delete(prefix);
};

//Patch User
export const apiPatchUser = (id, user) => {
  const prefix = `/users/patch/${id}`;
  return axios.patch(prefix, user);
};