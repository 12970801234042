import {
  //SAVE
    ECOS_SAVE_FAILED,
    ECOS_SAVE_SUCCESS,
    ECOS_IS_SAVING,
    //LOAD
    ECOS_FETCH_FAILED,
    ECOS_FETCH_SUCCESS,
    ECOS_IS_FETCHING,
    //UPDATE
    ECOS_IS_UPDATING,
    ECOS_UPDATE_FAILED,
    ECOS_UPDATE_SUCCESS,
    //DELETE
    ECOS_DELETE_SUCCESS,
    ECOS_DELETE_FAILED,
    ECOS_IS_DELETING,
    //PATCH
    ECOS_IS_PATCHING,
    ECOS_PATCH_FAILED,
    ECOS_PATCH_SUCCESS
   
  } from "../types/ecosTypes";
  
  var initialState = {
    ecosSaved: false,
    ecosIsSaving: false,
    ecosIsFetching: false,
    ecosUpdated: false,
    ecosIsUpdating: false,
    ecosPatched: false,
    ecosIsPatching: false,
    ecosDeleted: false,
    ecosIsDeleting: false,
    error: null,
    ecos: [],
    ecos:{}
  };
  
  const ecosReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case ECOS_IS_SAVING:
        return {
          ...state,
          ecosIsSaving: true,
          error: null
        };
      case ECOS_SAVE_SUCCESS:
        return {
          ...state,
          ecosSaved: true,
          ecosIsSaving: false,
          error: null,
          ecos:action.payload
        };
      case ECOS_SAVE_FAILED:
        return {
          ...state,
          ecosIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case ECOS_IS_FETCHING:
        return { ...state,
          ecosIsFetching: true,
          error: null };
      case ECOS_FETCH_SUCCESS:
        return {
          ...state,
          ecosIsFetching: false,
          ecos: action.payload,
          error: null
        };
      case ECOS_FETCH_FAILED:
        return {
          ...state,
          ecosIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case ECOS_IS_UPDATING:
        return { ...state, ecosIsUpdating: true,error: null };
      case ECOS_UPDATE_SUCCESS:
        return {
          ...state,
          ecosUpdated: true,
          ecosIsUpdating: false,
          error: null
        };
      case ECOS_UPDATE_FAILED:
        return {
          ...state,
          ecosIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case ECOS_IS_PATCHING:
        return { ...state, ecosIsPatching: true,error: null };
      case ECOS_PATCH_SUCCESS:
        return {
          ...state,
          ecosPatched: true,
          ecosIsPatching: false,
          error: null
        };
      case ECOS_PATCH_FAILED:
        return {
          ...state,
          ecosIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case ECOS_IS_DELETING:
      return { ...state, ecosIsDeleting: true ,error: null};
    case ECOS_DELETE_SUCCESS:
      return {
        ...state,
        ecosDeleted: true,
        ecosIsDeleting: false,
        error: null
      };
    case ECOS_DELETE_FAILED:
      return {
        ...state,
        ecosIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default ecosReducer;
  
  