/*-----------Types of SERVICE actions--------*/

/*---SAVE----*/
export const SERVICE_SAVE_SUCCESS = "SERVICE_SAVE_SUCCESS";
export const SERVICE_SAVE_FAILED = "SERVICE_SAVE_FAILED";
export const SERVICE_IS_SAVING = "SERVICE_IS_SAVING";
/*---FETCH----*/
export const SERVICE_FETCH_SUCCESS = "SERVICE_FETCH_SUCCESS";
export const SERVICE_FETCH_FAILED = "SERVICE_FETCH_FAILED";
export const SERVICE_IS_FETCHING = "SERVICE_IS_FETCHING";

/*---UPDATE----*/
export const SERVICE_UPDATE_SUCCESS = "SERVICE_UPDATE_SUCCESS";
export const SERVICE_UPDATE_FAILED = "SERVICE_UPDATE_FAILED";
export const SERVICE_IS_UPDATING = "SERVICE_IS_UPDATING";

/*---PATCH----*/
export const SERVICE_PATCH_SUCCESS = "SERVICE_PATCH_SUCCESS";
export const SERVICE_PATCH_FAILED = "SERVICE_PATCH_FAILED";
export const SERVICE_IS_PATCHING = "SERVICE_IS_PATCHING";


/*---DELETE----*/
export const SERVICE_DELETE_SUCCESS = "SERVICE_DELETE_SUCCESS";
export const SERVICE_DELETE_FAILED = "SERVICE_DELETE_FAILED";
export const SERVICE_IS_DELETING = "SERVICE_IS_DELETING";