/*-----------Types of PUBLICATION actions--------*/

/*---SAVE----*/
export const PUBLICATION_SAVE_SUCCESS = "PUBLICATION_SAVE_SUCCESS";
export const PUBLICATION_SAVE_FAILED = "PUBLICATION_SAVE_FAILED";
export const PUBLICATION_IS_SAVING = "PUBLICATION_IS_SAVING";
/*---FETCH----*/
export const PUBLICATION_FETCH_SUCCESS = "PUBLICATION_FETCH_SUCCESS";
export const PUBLICATION_FETCH_FAILED = "PUBLICATION_FETCH_FAILED";
export const PUBLICATION_IS_FETCHING = "PUBLICATION_IS_FETCHING";

/*---UPDATE----*/
export const PUBLICATION_UPDATE_SUCCESS = "PUBLICATION_UPDATE_SUCCESS";
export const PUBLICATION_UPDATE_FAILED = "PUBLICATION_UPDATE_FAILED";
export const PUBLICATION_IS_UPDATING = "PUBLICATION_IS_UPDATING";

/*---PATCH----*/
export const PUBLICATION_PATCH_SUCCESS = "PUBLICATION_PATCH_SUCCESS";
export const PUBLICATION_PATCH_FAILED = "PUBLICATION_PATCH_FAILED";
export const PUBLICATION_IS_PATCHING = "PUBLICATION_IS_PATCHING";


/*---DELETE----*/
export const PUBLICATION_DELETE_SUCCESS = "PUBLICATION_DELETE_SUCCESS";
export const PUBLICATION_DELETE_FAILED = "PUBLICATION_DELETE_FAILED";
export const PUBLICATION_IS_DELETING = "PUBLICATION_IS_DELETING";