import {
  //SAVE
    PROMO_SAVE_FAILED,
    PROMO_SAVE_SUCCESS,
    PROMO_IS_SAVING,
    //LOAD
    PROMO_FETCH_FAILED,
    PROMO_FETCH_SUCCESS,
    PROMO_IS_FETCHING,
    //UPDATE
    PROMO_IS_UPDATING,
    PROMO_UPDATE_FAILED,
    PROMO_UPDATE_SUCCESS,
    //DELETE
    PROMO_DELETE_SUCCESS,
    PROMO_DELETE_FAILED,
    PROMO_IS_DELETING,
    //PATCH
    PROMO_IS_PATCHING,
    PROMO_PATCH_FAILED,
    PROMO_PATCH_SUCCESS
   
  } from "../types/promoTypes";
  
  var initialState = {
    promoSaved: false,
    promoIsSaving: false,
    promoIsFetching: false,
    promoUpdated: false,
    promoIsUpdating: false,
    promoPatched: false,
    promoIsPatching: false,
    promoDeleted: false,
    promoIsDeleting: false,
    error: null,
    promos: [],
    promo:{}
  };
  
  const promoReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case PROMO_IS_SAVING:
        return {
          ...state,
          promoIsSaving: true,
          error: null
        };
      case PROMO_SAVE_SUCCESS:
        return {
          ...state,
          promoSaved: true,
          promoIsSaving: false,
          error: null,
          promo:action.payload
        };
      case PROMO_SAVE_FAILED:
        return {
          ...state,
          promoIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case PROMO_IS_FETCHING:
        return { ...state,
          promoIsFetching: true,
          error: null };
      case PROMO_FETCH_SUCCESS:
        return {
          ...state,
          promoIsFetching: false,
          promos: action.payload,
          error: null
        };
      case PROMO_FETCH_FAILED:
        return {
          ...state,
          promoIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case PROMO_IS_UPDATING:
        return { ...state, promoIsUpdating: true,error: null };
      case PROMO_UPDATE_SUCCESS:
        return {
          ...state,
          promoUpdated: true,
          promoIsUpdating: false,
          error: null
        };
      case PROMO_UPDATE_FAILED:
        return {
          ...state,
          promoIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case PROMO_IS_PATCHING:
        return { ...state, promoIsPatching: true,error: null };
      case PROMO_PATCH_SUCCESS:
        return {
          ...state,
          promoPatched: true,
          promoIsPatching: false,
          error: null
        };
      case PROMO_PATCH_FAILED:
        return {
          ...state,
          promoIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case PROMO_IS_DELETING:
      return { ...state, promoIsDeleting: true ,error: null};
    case PROMO_DELETE_SUCCESS:
      return {
        ...state,
        promoDeleted: true,
        promoIsDeleting: false,
        error: null
      };
    case PROMO_DELETE_FAILED:
      return {
        ...state,
        promoIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default promoReducer;
  
  