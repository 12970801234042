import React, { useEffect } from "react";
import { Switch, Route, HashRouter } from "react-router-dom";
import Loadable from "react-loadable";
import BeatLoader from "react-spinners/BeatLoader"
import { useIsAuthenticated,AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import ProtectedRoute from "./pages/protectedRoutes/protectedRoute";
import {connect} from "react-redux"


const loading = () => (
  <div className="animated fadeIn pt-3 text-center mx-auto" style={{marginTop:250}}><BeatLoader color="#067BE3" loading={true} size={12}/></div>

  );

// Containers
const Home = Loadable({
  loader: () => import("./pages/home"),
  loading
});

//Login page
const Login = Loadable({
  loader: () => import("./pages/login"),
  loading

});
//QrCodeReader page
const QrCodeReader = Loadable({
  loader: () => import("./pages/QrCodeReader_page"),
  loading

});


function App({isAuth}) {
  let isAuthenticated = useIsAuthenticated();
  console.log("isAuthenticated-MS:",isAuthenticated)
  return (
    <HashRouter>
     <Switch>
     <Route path="/qrCode" component={QrCodeReader}/>
      {(isAuthenticated==true&&isAuth==true)? ( <Home/>): (<Login/>)}
      
      </Switch> 
    </HashRouter>
  );
}

const mapStateToProps = ({ authReducer }) => {
  return {
    isAuth: authReducer.isAuth,
    isLocked: authReducer.isLocked
  };
};

export default connect(mapStateToProps) (App);
