import {
  //SAVE
   HEALTHINNOV_SAVE_FAILED,
   HEALTHINNOV_SAVE_SUCCESS,
   HEALTHINNOV_IS_SAVING,
    //LOAD
   HEALTHINNOV_FETCH_FAILED,
   HEALTHINNOV_FETCH_SUCCESS,
   HEALTHINNOV_IS_FETCHING,
    //UPDATE
   HEALTHINNOV_IS_UPDATING,
   HEALTHINNOV_UPDATE_FAILED,
   HEALTHINNOV_UPDATE_SUCCESS,
    //DELETE
   HEALTHINNOV_DELETE_SUCCESS,
   HEALTHINNOV_DELETE_FAILED,
   HEALTHINNOV_IS_DELETING,
    //PATCH
   HEALTHINNOV_IS_PATCHING,
   HEALTHINNOV_PATCH_FAILED,
   HEALTHINNOV_PATCH_SUCCESS
   
  } from "../types/healthInnovTypes";
  
  var initialState = {
    healthInnovSaved: false,
    healthInnovIsSaving: false,
    healthInnovIsFetching: false,
    healthInnovUpdated: false,
    healthInnovIsUpdating: false,
    healthInnovPatched: false,
    healthInnovIsPatching: false,
    healthInnovDeleted: false,
    healthInnovIsDeleting: false,
    error: null,
    healthInnovs: [],
    healthInnov:{}
  };
  
  const healthInnovReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case HEALTHINNOV_IS_SAVING:
        return {
          ...state,
          healthInnovIsSaving: true,
          error: null
        };
      case HEALTHINNOV_SAVE_SUCCESS:
        return {
          ...state,
          healthInnovSaved: true,
          healthInnovIsSaving: false,
          error: null,
          healthInnov:action.payload
        };
      case HEALTHINNOV_SAVE_FAILED:
        return {
          ...state,
          healthInnovIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case HEALTHINNOV_IS_FETCHING:
        return { ...state,
          healthInnovIsFetching: true,
          error: null };
      case HEALTHINNOV_FETCH_SUCCESS:
        return {
          ...state,
          healthInnovIsFetching: false,
          healthInnovs: action.payload,
          error: null
        };
      case HEALTHINNOV_FETCH_FAILED:
        return {
          ...state,
          healthInnovIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case HEALTHINNOV_IS_UPDATING:
        return { ...state, healthInnovIsUpdating: true,error: null };
      case HEALTHINNOV_UPDATE_SUCCESS:
        return {
          ...state,
          healthInnovUpdated: true,
          healthInnovIsUpdating: false,
          error: null
        };
      case HEALTHINNOV_UPDATE_FAILED:
        return {
          ...state,
          healthInnovIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case HEALTHINNOV_IS_PATCHING:
        return { ...state, healthInnovIsPatching: true,error: null };
      case HEALTHINNOV_PATCH_SUCCESS:
        return {
          ...state,
          healthInnovPatched: true,
          healthInnovIsPatching: false,
          error: null
        };
      case HEALTHINNOV_PATCH_FAILED:
        return {
          ...state,
          healthInnovIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case HEALTHINNOV_IS_DELETING:
      return { ...state, healthInnovIsDeleting: true ,error: null};
    case HEALTHINNOV_DELETE_SUCCESS:
      return {
        ...state,
        healthInnovDeleted: true,
        healthInnovIsDeleting: false,
        error: null
      };
    case HEALTHINNOV_DELETE_FAILED:
      return {
        ...state,
        healthInnovIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default healthInnovReducer;
  
  