import {
  //SAVE
    FILE_SAVE_FAILED,
    FILE_SAVE_SUCCESS,
    FILE_IS_SAVING,
    //LOAD
    FILE_FETCH_FAILED,
    FILE_FETCH_SUCCESS,
    FILE_IS_FETCHING,
    //UPDATE
    FILE_IS_UPDATING,
    FILE_UPDATE_FAILED,
    FILE_UPDATE_SUCCESS,
    //DELETE
    FILE_DELETE_SUCCESS,
    FILE_DELETE_FAILED,
    FILE_IS_DELETING,
    //PATCH
    FILE_IS_PATCHING,
    FILE_PATCH_FAILED,
    FILE_PATCH_SUCCESS
   
  } from "../types/fileTypes";
  
  var initialState = {
    fileSaved: false,
    fileIsSaving: false,
    fileIsFetching: false,
    fileUpdated: false,
    fileIsUpdating: false,
    filePatched: false,
    fileIsPatching: false,
    fileDeleted: false,
    fileIsDeleting: false,
    error: null,
    files: [],
    file:{}
  };
  
  const fileReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case FILE_IS_SAVING:
        return {
          ...state,
          fileIsSaving: true,
          error: null
        };
      case FILE_SAVE_SUCCESS:
        return {
          ...state,
          fileSaved: true,
          fileIsSaving: false,
          error: null,
          file:action.payload
        };
      case FILE_SAVE_FAILED:
        return {
          ...state,
          fileIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case FILE_IS_FETCHING:
        return { ...state,
          fileIsFetching: true,
          error: null };
      case FILE_FETCH_SUCCESS:
        return {
          ...state,
          fileIsFetching: false,
          files: action.payload,
          error: null
        };
      case FILE_FETCH_FAILED:
        return {
          ...state,
          fileIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case FILE_IS_UPDATING:
        return { ...state, fileIsUpdating: true,error: null };
      case FILE_UPDATE_SUCCESS:
        return {
          ...state,
          fileUpdated: true,
          fileIsUpdating: false,
          error: null
        };
      case FILE_UPDATE_FAILED:
        return {
          ...state,
          fileIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case FILE_IS_PATCHING:
        return { ...state, fileIsPatching: true,error: null };
      case FILE_PATCH_SUCCESS:
        return {
          ...state,
          filePatched: true,
          fileIsPatching: false,
          error: null
        };
      case FILE_PATCH_FAILED:
        return {
          ...state,
          fileIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case FILE_IS_DELETING:
      return { ...state, fileIsDeleting: true ,error: null};
    case FILE_DELETE_SUCCESS:
      return {
        ...state,
        fileDeleted: true,
        fileIsDeleting: false,
        error: null
      };
    case FILE_DELETE_FAILED:
      return {
        ...state,
        fileIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default fileReducer;
  
  