/*-----------Types of FROMATION actions--------*/

/*---SAVE----*/
export const THESIS_SAVE_SUCCESS = "THESIS_SAVE_SUCCESS";
export const THESIS_SAVE_FAILED = "THESIS_SAVE_FAILED";
export const THESIS_IS_SAVING = "THESIS_IS_SAVING";
/*---FETCH----*/
export const THESIS_FETCH_SUCCESS = "THESIS_FETCH_SUCCESS";
export const THESIS_FETCH_FAILED = "THESIS_FETCH_FAILED";
export const THESIS_IS_FETCHING = "THESIS_IS_FETCHING";

/*---UPDATE----*/
export const THESIS_UPDATE_SUCCESS = "THESIS_UPDATE_SUCCESS";
export const THESIS_UPDATE_FAILED = "THESIS_UPDATE_FAILED";
export const THESIS_IS_UPDATING = "THESIS_IS_UPDATING";

/*---PATCH----*/
export const THESIS_PATCH_SUCCESS = "THESIS_PATCH_SUCCESS";
export const THESIS_PATCH_FAILED = "THESIS_PATCH_FAILED";
export const THESIS_IS_PATCHING = "THESIS_IS_PATCHING";


/*---DELETE----*/
export const THESIS_DELETE_SUCCESS = "THESIS_DELETE_SUCCESS";
export const THESIS_DELETE_FAILED = "THESIS_DELETE_FAILED";
export const THESIS_IS_DELETING = "THESIS_IS_DELETING";