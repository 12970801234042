/*-----------Types of EVENT actions--------*/

/*---SAVE----*/
export const COURSE_SAVE_SUCCESS = "COURSE_SAVE_SUCCESS";
export const COURSE_SAVE_FAILED = "COURSE_SAVE_FAILED";
export const COURSE_IS_SAVING = "COURSE_IS_SAVING";
/*---FETCH----*/
export const COURSE_FETCH_SUCCESS = "COURSE_FETCH_SUCCESS";
export const COURSE_FETCH_FAILED = "COURSE_FETCH_FAILED";
export const COURSE_IS_FETCHING = "COURSE_IS_FETCHING";

/*---UPDATE----*/
export const COURSE_UPDATE_SUCCESS = "COURSE_UPDATE_SUCCESS";
export const COURSE_UPDATE_FAILED = "COURSE_UPDATE_FAILED";
export const COURSE_IS_UPDATING = "COURSE_IS_UPDATING";

/*---PATCH----*/
export const COURSE_PATCH_SUCCESS = "COURSE_PATCH_SUCCESS";
export const COURSE_PATCH_FAILED = "COURSE_PATCH_FAILED";
export const COURSE_IS_PATCHING = "COURSE_IS_PATCHING";


/*---DELETE----*/
export const COURSE_DELETE_SUCCESS = "COURSE_DELETE_SUCCESS";
export const COURSE_DELETE_FAILED = "COURSE_DELETE_FAILED";
export const COURSE_IS_DELETING = "COURSE_IS_DELETING";