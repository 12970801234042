import {
  //SAVE
    SERVICE_SAVE_FAILED,
    SERVICE_SAVE_SUCCESS,
    SERVICE_IS_SAVING,
    //LOAD
    SERVICE_FETCH_FAILED,
    SERVICE_FETCH_SUCCESS,
    SERVICE_IS_FETCHING,
    //UPDATE
    SERVICE_IS_UPDATING,
    SERVICE_UPDATE_FAILED,
    SERVICE_UPDATE_SUCCESS,
    //DELETE
    SERVICE_DELETE_SUCCESS,
    SERVICE_DELETE_FAILED,
    SERVICE_IS_DELETING,
    //PATCH
    SERVICE_IS_PATCHING,
    SERVICE_PATCH_FAILED,
    SERVICE_PATCH_SUCCESS
   
  } from "../types/serviceTypes";
  
  var initialState = {
    serviceSaved: false,
    serviceIsSaving: false,
    serviceIsFetching: false,
    serviceUpdated: false,
    serviceIsUpdating: false,
    servicePatched: false,
    serviceIsPatching: false,
    serviceDeleted: false,
    serviceIsDeleting: false,
    error: null,
    services: [],
    service:{}
  };
  
  const serviceReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case SERVICE_IS_SAVING:
        return {
          ...state,
          serviceIsSaving: true,
          error: null
        };
      case SERVICE_SAVE_SUCCESS:
        return {
          ...state,
          serviceSaved: true,
          serviceIsSaving: false,
          error: null,
          service:action.payload
        };
      case SERVICE_SAVE_FAILED:
        return {
          ...state,
          serviceIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case SERVICE_IS_FETCHING:
        return { ...state,
          serviceIsFetching: true,
          error: null };
      case SERVICE_FETCH_SUCCESS:
        return {
          ...state,
          serviceIsFetching: false,
          services: action.payload,
          error: null
        };
      case SERVICE_FETCH_FAILED:
        return {
          ...state,
          serviceIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case SERVICE_IS_UPDATING:
        return { ...state, serviceIsUpdating: true,error: null };
      case SERVICE_UPDATE_SUCCESS:
        return {
          ...state,
          serviceUpdated: true,
          serviceIsUpdating: false,
          error: null
        };
      case SERVICE_UPDATE_FAILED:
        return {
          ...state,
          serviceIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case SERVICE_IS_PATCHING:
        return { ...state, serviceIsPatching: true,error: null };
      case SERVICE_PATCH_SUCCESS:
        return {
          ...state,
          servicePatched: true,
          serviceIsPatching: false,
          error: null
        };
      case SERVICE_PATCH_FAILED:
        return {
          ...state,
          serviceIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case SERVICE_IS_DELETING:
      return { ...state, serviceIsDeleting: true ,error: null};
    case SERVICE_DELETE_SUCCESS:
      return {
        ...state,
        serviceDeleted: true,
        serviceIsDeleting: false,
        error: null
      };
    case SERVICE_DELETE_FAILED:
      return {
        ...state,
        serviceIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default serviceReducer;
  
  