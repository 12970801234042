/*-----------Types of HEALTHINNOV actions--------*/

/*---SAVE----*/
export const HEALTHINNOV_SAVE_SUCCESS = "HEALTHINNOV_SAVE_SUCCESS";
export const HEALTHINNOV_SAVE_FAILED = "HEALTHINNOV_SAVE_FAILED";
export const HEALTHINNOV_IS_SAVING = "HEALTHINNOV_IS_SAVING";
/*---FETCH----*/
export const HEALTHINNOV_FETCH_SUCCESS = "HEALTHINNOV_FETCH_SUCCESS";
export const HEALTHINNOV_FETCH_FAILED = "HEALTHINNOV_FETCH_FAILED";
export const HEALTHINNOV_IS_FETCHING = "HEALTHINNOV_IS_FETCHING";

/*---UPDATE----*/
export const HEALTHINNOV_UPDATE_SUCCESS = "HEALTHINNOV_UPDATE_SUCCESS";
export const HEALTHINNOV_UPDATE_FAILED = "HEALTHINNOV_UPDATE_FAILED";
export const HEALTHINNOV_IS_UPDATING = "HEALTHINNOV_IS_UPDATING";

/*---PATCH----*/
export const HEALTHINNOV_PATCH_SUCCESS = "HEALTHINNOV_PATCH_SUCCESS";
export const HEALTHINNOV_PATCH_FAILED = "HEALTHINNOV_PATCH_FAILED";
export const HEALTHINNOV_IS_PATCHING = "HEALTHINNOV_IS_PATCHING";


/*---DELETE----*/
export const HEALTHINNOV_DELETE_SUCCESS = "HEALTHINNOV_DELETE_SUCCESS";
export const HEALTHINNOV_DELETE_FAILED = "HEALTHINNOV_DELETE_FAILED";
export const HEALTHINNOV_IS_DELETING = "HEALTHINNOV_IS_DELETING";