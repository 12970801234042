/*-----------Types of INNOV actions--------*/

/*---SAVE----*/
export const INNOV_SAVE_SUCCESS = "INNOV_SAVE_SUCCESS";
export const INNOV_SAVE_FAILED = "INNOV_SAVE_FAILED";
export const INNOV_IS_SAVING = "INNOV_IS_SAVING";
/*---FETCH----*/
export const INNOV_FETCH_SUCCESS = "INNOV_FETCH_SUCCESS";
export const INNOV_FETCH_FAILED = "INNOV_FETCH_FAILED";
export const INNOV_IS_FETCHING = "INNOV_IS_FETCHING";

/*---UPDATE----*/
export const INNOV_UPDATE_SUCCESS = "INNOV_UPDATE_SUCCESS";
export const INNOV_UPDATE_FAILED = "INNOV_UPDATE_FAILED";
export const INNOV_IS_UPDATING = "INNOV_IS_UPDATING";

/*---PATCH----*/
export const INNOV_PATCH_SUCCESS = "INNOV_PATCH_SUCCESS";
export const INNOV_PATCH_FAILED = "INNOV_PATCH_FAILED";
export const INNOV_IS_PATCHING = "INNOV_IS_PATCHING";


/*---DELETE----*/
export const INNOV_DELETE_SUCCESS = "INNOV_DELETE_SUCCESS";
export const INNOV_DELETE_FAILED = "INNOV_DELETE_FAILED";
export const INNOV_IS_DELETING = "INNOV_IS_DELETING";