/*-----------Types of FROMATION actions--------*/

/*---SAVE----*/
export const SEMINAR_SAVE_SUCCESS = "SEMINAR_SAVE_SUCCESS";
export const SEMINAR_SAVE_FAILED = "SEMINAR_SAVE_FAILED";
export const SEMINAR_IS_SAVING = "SEMINAR_IS_SAVING";
/*---FETCH----*/
export const SEMINAR_FETCH_SUCCESS = "SEMINAR_FETCH_SUCCESS";
export const SEMINAR_FETCH_FAILED = "SEMINAR_FETCH_FAILED";
export const SEMINAR_IS_FETCHING = "SEMINAR_IS_FETCHING";

/*---UPDATE----*/
export const SEMINAR_UPDATE_SUCCESS = "SEMINAR_UPDATE_SUCCESS";
export const SEMINAR_UPDATE_FAILED = "SEMINAR_UPDATE_FAILED";
export const SEMINAR_IS_UPDATING = "SEMINAR_IS_UPDATING";

/*---PATCH----*/
export const SEMINAR_PATCH_SUCCESS = "SEMINAR_PATCH_SUCCESS";
export const SEMINAR_PATCH_FAILED = "SEMINAR_PATCH_FAILED";
export const SEMINAR_IS_PATCHING = "SEMINAR_IS_PATCHING";


/*---DELETE----*/
export const SEMINAR_DELETE_SUCCESS = "SEMINAR_DELETE_SUCCESS";
export const SEMINAR_DELETE_FAILED = "SEMINAR_DELETE_FAILED";
export const SEMINAR_IS_DELETING = "SEMINAR_IS_DELETING";