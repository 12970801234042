import {
  //SAVE
   INNOV_SAVE_FAILED,
   INNOV_SAVE_SUCCESS,
   INNOV_IS_SAVING,
    //LOAD
   INNOV_FETCH_FAILED,
   INNOV_FETCH_SUCCESS,
   INNOV_IS_FETCHING,
    //UPDATE
   INNOV_IS_UPDATING,
   INNOV_UPDATE_FAILED,
   INNOV_UPDATE_SUCCESS,
    //DELETE
   INNOV_DELETE_SUCCESS,
   INNOV_DELETE_FAILED,
   INNOV_IS_DELETING,
    //PATCH
   INNOV_IS_PATCHING,
   INNOV_PATCH_FAILED,
   INNOV_PATCH_SUCCESS
   
  } from "../types/innovTypes";
  
  var initialState = {
    innovSaved: false,
    innovIsSaving: false,
    innovIsFetching: false,
    innovUpdated: false,
    innovIsUpdating: false,
    innovPatched: false,
    innovIsPatching: false,
    innovDeleted: false,
    innovIsDeleting: false,
    error: null,
    innovs: [],
    innov:{}
  };
  
  const innovReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case INNOV_IS_SAVING:
        return {
          ...state,
          innovIsSaving: true,
          error: null
        };
      case INNOV_SAVE_SUCCESS:
        return {
          ...state,
          innovSaved: true,
          innovIsSaving: false,
          error: null,
          innov:action.payload
        };
      case INNOV_SAVE_FAILED:
        return {
          ...state,
          innovIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case INNOV_IS_FETCHING:
        return { ...state,
          innovIsFetching: true,
          error: null };
      case INNOV_FETCH_SUCCESS:
        return {
          ...state,
          innovIsFetching: false,
          innovs: action.payload,
          error: null
        };
      case INNOV_FETCH_FAILED:
        return {
          ...state,
          innovIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case INNOV_IS_UPDATING:
        return { ...state, innovIsUpdating: true,error: null };
      case INNOV_UPDATE_SUCCESS:
        return {
          ...state,
          innovUpdated: true,
          innovIsUpdating: false,
          error: null
        };
      case INNOV_UPDATE_FAILED:
        return {
          ...state,
          innovIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case INNOV_IS_PATCHING:
        return { ...state, innovIsPatching: true,error: null };
      case INNOV_PATCH_SUCCESS:
        return {
          ...state,
          innovPatched: true,
          innovIsPatching: false,
          error: null
        };
      case INNOV_PATCH_FAILED:
        return {
          ...state,
          innovIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case INNOV_IS_DELETING:
      return { ...state, innovIsDeleting: true ,error: null};
    case INNOV_DELETE_SUCCESS:
      return {
        ...state,
        innovDeleted: true,
        innovIsDeleting: false,
        error: null
      };
    case INNOV_DELETE_FAILED:
      return {
        ...state,
        innovIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default innovReducer;
  
  