import {
  //SAVE
    SUPPORT_SAVE_FAILED,
    SUPPORT_SAVE_SUCCESS,
    SUPPORT_IS_SAVING,
    //LOAD
    SUPPORT_FETCH_FAILED,
    SUPPORT_FETCH_SUCCESS,
    SUPPORT_IS_FETCHING,
    //UPDATE
    SUPPORT_IS_UPDATING,
    SUPPORT_UPDATE_FAILED,
    SUPPORT_UPDATE_SUCCESS,
    //DELETE
    SUPPORT_DELETE_SUCCESS,
    SUPPORT_DELETE_FAILED,
    SUPPORT_IS_DELETING,
    //PATCH
    SUPPORT_IS_PATCHING,
    SUPPORT_PATCH_FAILED,
    SUPPORT_PATCH_SUCCESS
   
  } from "../types/supportTypes";
  
  var initialState = {
    supportSaved: false,
    supportIsSaving: false,
    supportIsFetching: false,
    supportUpdated: false,
    supportIsUpdating: false,
    supportPatched: false,
    supportIsPatching: false,
    supportDeleted: false,
    supportIsDeleting: false,
    error: null,
    supports: [],
    support:{}
  };
  
  const supportReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case SUPPORT_IS_SAVING:
        return {
          ...state,
          supportIsSaving: true,
          error: null
        };
      case SUPPORT_SAVE_SUCCESS:
        return {
          ...state,
          supportSaved: true,
          supportIsSaving: false,
          error: null,
          support:action.payload
        };
      case SUPPORT_SAVE_FAILED:
        return {
          ...state,
          supportIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case SUPPORT_IS_FETCHING:
        return { ...state,
          supportIsFetching: true,
          error: null };
      case SUPPORT_FETCH_SUCCESS:
        return {
          ...state,
          supportIsFetching: false,
          supports: action.payload,
          error: null
        };
      case SUPPORT_FETCH_FAILED:
        return {
          ...state,
          supportIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case SUPPORT_IS_UPDATING:
        return { ...state, supportIsUpdating: true,error: null };
      case SUPPORT_UPDATE_SUCCESS:
        return {
          ...state,
          supportUpdated: true,
          supportIsUpdating: false,
          error: null
        };
      case SUPPORT_UPDATE_FAILED:
        return {
          ...state,
          supportIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case SUPPORT_IS_PATCHING:
        return { ...state, supportIsPatching: true,error: null };
      case SUPPORT_PATCH_SUCCESS:
        return {
          ...state,
          supportPatched: true,
          supportIsPatching: false,
          error: null
        };
      case SUPPORT_PATCH_FAILED:
        return {
          ...state,
          supportIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case SUPPORT_IS_DELETING:
      return { ...state, supportIsDeleting: true ,error: null};
    case SUPPORT_DELETE_SUCCESS:
      return {
        ...state,
        supportDeleted: true,
        supportIsDeleting: false,
        error: null
      };
    case SUPPORT_DELETE_FAILED:
      return {
        ...state,
        supportIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default supportReducer;
  
  