/*-----------Types of FROMATION actions--------*/

/*---SAVE----*/
export const POSTCOURSE_SAVE_SUCCESS = "POSTCOURSE_SAVE_SUCCESS";
export const POSTCOURSE_SAVE_FAILED = "POSTCOURSE_SAVE_FAILED";
export const POSTCOURSE_IS_SAVING = "POSTCOURSE_IS_SAVING";
/*---FETCH----*/
export const POSTCOURSE_FETCH_SUCCESS = "POSTCOURSE_FETCH_SUCCESS";
export const POSTCOURSE_FETCH_FAILED = "POSTCOURSE_FETCH_FAILED";
export const POSTCOURSE_IS_FETCHING = "POSTCOURSE_IS_FETCHING";

/*---UPDATE----*/
export const POSTCOURSE_UPDATE_SUCCESS = "POSTCOURSE_UPDATE_SUCCESS";
export const POSTCOURSE_UPDATE_FAILED = "POSTCOURSE_UPDATE_FAILED";
export const POSTCOURSE_IS_UPDATING = "POSTCOURSE_IS_UPDATING";

/*---PATCH----*/
export const POSTCOURSE_PATCH_SUCCESS = "POSTCOURSE_PATCH_SUCCESS";
export const POSTCOURSE_PATCH_FAILED = "POSTCOURSE_PATCH_FAILED";
export const POSTCOURSE_IS_PATCHING = "POSTCOURSE_IS_PATCHING";


/*---DELETE----*/
export const POSTCOURSE_DELETE_SUCCESS = "POSTCOURSE_DELETE_SUCCESS";
export const POSTCOURSE_DELETE_FAILED = "POSTCOURSE_DELETE_FAILED";
export const POSTCOURSE_IS_DELETING = "POSTCOURSE_IS_DELETING";