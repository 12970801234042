/*-----------Types of ECOS actions--------*/

/*---SAVE----*/
export const ECOS_SAVE_SUCCESS = "ECOS_SAVE_SUCCESS";
export const ECOS_SAVE_FAILED = "ECOS_SAVE_FAILED";
export const ECOS_IS_SAVING = "ECOS_IS_SAVING";
/*---FETCH----*/
export const ECOS_FETCH_SUCCESS = "ECOS_FETCH_SUCCESS";
export const ECOS_FETCH_FAILED = "ECOS_FETCH_FAILED";
export const ECOS_IS_FETCHING = "ECOS_IS_FETCHING";

/*---UPDATE----*/
export const ECOS_UPDATE_SUCCESS = "ECOS_UPDATE_SUCCESS";
export const ECOS_UPDATE_FAILED = "ECOS_UPDATE_FAILED";
export const ECOS_IS_UPDATING = "ECOS_IS_UPDATING";

/*---PATCH----*/
export const ECOS_PATCH_SUCCESS = "ECOS_PATCH_SUCCESS";
export const ECOS_PATCH_FAILED = "ECOS_PATCH_FAILED";
export const ECOS_IS_PATCHING = "ECOS_IS_PATCHING";


/*---DELETE----*/
export const ECOS_DELETE_SUCCESS = "ECOS_DELETE_SUCCESS";
export const ECOS_DELETE_FAILED = "ECOS_DELETE_FAILED";
export const ECOS_IS_DELETING = "ECOS_IS_DELETING";