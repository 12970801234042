import {
  //SAVE
    PUBLICATION_SAVE_FAILED,
    PUBLICATION_SAVE_SUCCESS,
    PUBLICATION_IS_SAVING,
    //LOAD
    PUBLICATION_FETCH_FAILED,
    PUBLICATION_FETCH_SUCCESS,
    PUBLICATION_IS_FETCHING,
    //UPDATE
    PUBLICATION_IS_UPDATING,
    PUBLICATION_UPDATE_FAILED,
    PUBLICATION_UPDATE_SUCCESS,
    //DELETE
    PUBLICATION_DELETE_SUCCESS,
    PUBLICATION_DELETE_FAILED,
    PUBLICATION_IS_DELETING,
    //PATCH
    PUBLICATION_IS_PATCHING,
    PUBLICATION_PATCH_FAILED,
    PUBLICATION_PATCH_SUCCESS
   
  } from "../types/publicationTypes";
  
  var initialState = {
    publicationSaved: false,
    publicationIsSaving: false,
    publicationIsFetching: false,
    publicationUpdated: false,
    publicationIsUpdating: false,
    publicationPatched: false,
    publicationIsPatching: false,
    publicationDeleted: false,
    publicationIsDeleting: false,
    error: null,
    publications: [],
    publication:{}
  };
  
  const publicationReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case PUBLICATION_IS_SAVING:
        return {
          ...state,
          publicationIsSaving: true,
          error: null
        };
      case PUBLICATION_SAVE_SUCCESS:
        return {
          ...state,
          publicationSaved: true,
          publicationIsSaving: false,
          error: null,
          publication:action.payload
        };
      case PUBLICATION_SAVE_FAILED:
        return {
          ...state,
          publicationIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case PUBLICATION_IS_FETCHING:
        return { ...state,
          publicationIsFetching: true,
          error: null };
      case PUBLICATION_FETCH_SUCCESS:
        return {
          ...state,
          publicationIsFetching: false,
          publications: action.payload,
          error: null
        };
      case PUBLICATION_FETCH_FAILED:
        return {
          ...state,
          publicationIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case PUBLICATION_IS_UPDATING:
        return { ...state, publicationIsUpdating: true,error: null };
      case PUBLICATION_UPDATE_SUCCESS:
        return {
          ...state,
          publicationUpdated: true,
          publicationIsUpdating: false,
          error: null
        };
      case PUBLICATION_UPDATE_FAILED:
        return {
          ...state,
          publicationIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case PUBLICATION_IS_PATCHING:
        return { ...state, publicationIsPatching: true,error: null };
      case PUBLICATION_PATCH_SUCCESS:
        return {
          ...state,
          publicationPatched: true,
          publicationIsPatching: false,
          error: null
        };
      case PUBLICATION_PATCH_FAILED:
        return {
          ...state,
          publicationIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case PUBLICATION_IS_DELETING:
      return { ...state, publicationIsDeleting: true ,error: null};
    case PUBLICATION_DELETE_SUCCESS:
      return {
        ...state,
        publicationDeleted: true,
        publicationIsDeleting: false,
        error: null
      };
    case PUBLICATION_DELETE_FAILED:
      return {
        ...state,
        publicationIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default publicationReducer;
  
  