import {
  //SAVE
    COURSE_SAVE_FAILED,
    COURSE_SAVE_SUCCESS,
    COURSE_IS_SAVING,
    //LOAD
    COURSE_FETCH_FAILED,
    COURSE_FETCH_SUCCESS,
    COURSE_IS_FETCHING,
    //UPDATE
    COURSE_IS_UPDATING,
    COURSE_UPDATE_FAILED,
    COURSE_UPDATE_SUCCESS,
    //DELETE
    COURSE_DELETE_SUCCESS,
    COURSE_DELETE_FAILED,
    COURSE_IS_DELETING,
    //PATCH
    COURSE_IS_PATCHING,
    COURSE_PATCH_FAILED,
    COURSE_PATCH_SUCCESS
   
  } from "../types/courseTypes";
  
  var initialState = {
    courseSaved: false,
    courseIsSaving: false,
    courseIsFetching: false,
    courseUpdated: false,
    courseIsUpdating: false,
    coursePatched: false,
    courseIsPatching: false,
    courseDeleted: false,
    courseIsDeleting: false,
    error: null,
    courses: [],
    course:{}
  };
  
  const courseReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case COURSE_IS_SAVING:
        return {
          ...state,
          courseIsSaving: true,
          error: null
        };
      case COURSE_SAVE_SUCCESS:
        return {
          ...state,
          courseSaved: true,
          courseIsSaving: false,
          error: null,
          course:action.payload
        };
      case COURSE_SAVE_FAILED:
        return {
          ...state,
          courseIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case COURSE_IS_FETCHING:
        return { ...state,
          courseIsFetching: true,
          error: null };
      case COURSE_FETCH_SUCCESS:
        return {
          ...state,
          courseIsFetching: false,
          courses: action.payload,
          error: null
        };
      case COURSE_FETCH_FAILED:
        return {
          ...state,
          courseIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case COURSE_IS_UPDATING:
        return { ...state, courseIsUpdating: true,error: null };
      case COURSE_UPDATE_SUCCESS:
        return {
          ...state,
          courseUpdated: true,
          courseIsUpdating: false,
          error: null
        };
      case COURSE_UPDATE_FAILED:
        return {
          ...state,
          courseIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case COURSE_IS_PATCHING:
        return { ...state, courseIsPatching: true,error: null };
      case COURSE_PATCH_SUCCESS:
        return {
          ...state,
          coursePatched: true,
          courseIsPatching: false,
          error: null
        };
      case COURSE_PATCH_FAILED:
        return {
          ...state,
          courseIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case COURSE_IS_DELETING:
      return { ...state, courseIsDeleting: true ,error: null};
    case COURSE_DELETE_SUCCESS:
      return {
        ...state,
        courseDeleted: true,
        courseIsDeleting: false,
        error: null
      };
    case COURSE_DELETE_FAILED:
      return {
        ...state,
        courseIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default courseReducer;
  
  