import {
  //SAVE
    POSTCOURSE_SAVE_FAILED,
    POSTCOURSE_SAVE_SUCCESS,
    POSTCOURSE_IS_SAVING,
    //LOAD
    POSTCOURSE_FETCH_FAILED,
    POSTCOURSE_FETCH_SUCCESS,
    POSTCOURSE_IS_FETCHING,
    //UPDATE
    POSTCOURSE_IS_UPDATING,
    POSTCOURSE_UPDATE_FAILED,
    POSTCOURSE_UPDATE_SUCCESS,
    //DELETE
    POSTCOURSE_DELETE_SUCCESS,
    POSTCOURSE_DELETE_FAILED,
    POSTCOURSE_IS_DELETING,
    //PATCH
    POSTCOURSE_IS_PATCHING,
    POSTCOURSE_PATCH_FAILED,
    POSTCOURSE_PATCH_SUCCESS
   
  } from "../types/postCourseTypes";
  
  var initialState = {
    postCourseSaved: false,
    postCourseIsSaving: false,
    postCourseIsFetching: false,
    postCourseUpdated: false,
    postCourseIsUpdating: false,
    postCoursePatched: false,
    postCourseIsPatching: false,
    postCourseDeleted: false,
    postCourseIsDeleting: false,
    error: null,
    postCourses: [],
    postCourse:{}
  };
  
  const postCourseReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case POSTCOURSE_IS_SAVING:
        return {
          ...state,
          postCourseIsSaving: true,
          error: null
        };
      case POSTCOURSE_SAVE_SUCCESS:
        return {
          ...state,
          postCourseSaved: true,
          postCourseIsSaving: false,
          error: null,
          postCourse:action.payload
        };
      case POSTCOURSE_SAVE_FAILED:
        return {
          ...state,
          postCourseIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case POSTCOURSE_IS_FETCHING:
        return { ...state,
          postCourseIsFetching: true,
          error: null };
      case POSTCOURSE_FETCH_SUCCESS:
        return {
          ...state,
          postCourseIsFetching: false,
          postCourses: action.payload,
          error: null
        };
      case POSTCOURSE_FETCH_FAILED:
        return {
          ...state,
          postCourseIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case POSTCOURSE_IS_UPDATING:
        return { ...state, postCourseIsUpdating: true,error: null };
      case POSTCOURSE_UPDATE_SUCCESS:
        return {
          ...state,
          postCourseUpdated: true,
          postCourseIsUpdating: false,
          error: null
        };
      case POSTCOURSE_UPDATE_FAILED:
        return {
          ...state,
          postCourseIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case POSTCOURSE_IS_PATCHING:
        return { ...state, postCourseIsPatching: true,error: null };
      case POSTCOURSE_PATCH_SUCCESS:
        return {
          ...state,
          postCoursePatched: true,
          postCourseIsPatching: false,
          error: null
        };
      case POSTCOURSE_PATCH_FAILED:
        return {
          ...state,
          postCourseIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case POSTCOURSE_IS_DELETING:
      return { ...state, postCourseIsDeleting: true ,error: null};
    case POSTCOURSE_DELETE_SUCCESS:
      return {
        ...state,
        postCourseDeleted: true,
        postCourseIsDeleting: false,
        error: null
      };
    case POSTCOURSE_DELETE_FAILED:
      return {
        ...state,
        postCourseIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default postCourseReducer;
  
  