/*-----------Types of FROMATION actions--------*/

/*---SAVE----*/
export const SUPPORT_SAVE_SUCCESS = "SUPPORT_SAVE_SUCCESS";
export const SUPPORT_SAVE_FAILED = "SUPPORT_SAVE_FAILED";
export const SUPPORT_IS_SAVING = "SUPPORT_IS_SAVING";
/*---FETCH----*/
export const SUPPORT_FETCH_SUCCESS = "SUPPORT_FETCH_SUCCESS";
export const SUPPORT_FETCH_FAILED = "SUPPORT_FETCH_FAILED";
export const SUPPORT_IS_FETCHING = "SUPPORT_IS_FETCHING";

/*---UPDATE----*/
export const SUPPORT_UPDATE_SUCCESS = "SUPPORT_UPDATE_SUCCESS";
export const SUPPORT_UPDATE_FAILED = "SUPPORT_UPDATE_FAILED";
export const SUPPORT_IS_UPDATING = "SUPPORT_IS_UPDATING";

/*---PATCH----*/
export const SUPPORT_PATCH_SUCCESS = "SUPPORT_PATCH_SUCCESS";
export const SUPPORT_PATCH_FAILED = "SUPPORT_PATCH_FAILED";
export const SUPPORT_IS_PATCHING = "SUPPORT_IS_PATCHING";


/*---DELETE----*/
export const SUPPORT_DELETE_SUCCESS = "SUPPORT_DELETE_SUCCESS";
export const SUPPORT_DELETE_FAILED = "SUPPORT_DELETE_FAILED";
export const SUPPORT_IS_DELETING = "SUPPORT_IS_DELETING";