/*-----------Types of Promotions actions--------*/

/*---SAVE----*/
export const PROMO_SAVE_SUCCESS = "PROMO_SAVE_SUCCESS";
export const PROMO_SAVE_FAILED = "PROMO_SAVE_FAILED";
export const PROMO_IS_SAVING = "PROMO_IS_SAVING";
/*---FETCH----*/
export const PROMO_FETCH_SUCCESS = "PROMO_FETCH_SUCCESS";
export const PROMO_FETCH_FAILED = "PROMO_FETCH_FAILED";
export const PROMO_IS_FETCHING = "PROMO_IS_FETCHING";

/*---UPDATE----*/
export const PROMO_UPDATE_SUCCESS = "PROMO_UPDATE_SUCCESS";
export const PROMO_UPDATE_FAILED = "PROMO_UPDATE_FAILED";
export const PROMO_IS_UPDATING = "PROMO_IS_UPDATING";

/*---PATCH----*/
export const PROMO_PATCH_SUCCESS = "PROMO_PATCH_SUCCESS";
export const PROMO_PATCH_FAILED = "PROMO_PATCH_FAILED";
export const PROMO_IS_PATCHING = "PROMO_IS_PATCHING";


/*---DELETE----*/
export const PROMO_DELETE_SUCCESS = "PROMO_DELETE_SUCCESS";
export const PROMO_DELETE_FAILED = "PROMO_DELETE_FAILED";
export const PROMO_IS_DELETING = "PROMO_IS_DELETING";