import {
  //SAVE
    RESP_SAVE_FAILED,
    RESP_SAVE_SUCCESS,
    RESP_IS_SAVING,
    //LOAD
    RESP_FETCH_FAILED,
    RESP_FETCH_SUCCESS,
    RESP_IS_FETCHING,
    //UPDATE
    RESP_IS_UPDATING,
    RESP_UPDATE_FAILED,
    RESP_UPDATE_SUCCESS,
    //DELETE
    RESP_DELETE_SUCCESS,
    RESP_DELETE_FAILED,
    RESP_IS_DELETING,
    //PATCH
    RESP_IS_PATCHING,
    RESP_PATCH_FAILED,
    RESP_PATCH_SUCCESS
   
  } from "../types/respTypes";
  
  var initialState = {
    respSaved: false,
    respIsSaving: false,
    respIsFetching: false,
    respUpdated: false,
    respIsUpdating: false,
    respPatched: false,
    respIsPatching: false,
    respDeleted: false,
    respIsDeleting: false,
    error: null,
    resps: [],
    resp:{}
  };
  
  const respReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case RESP_IS_SAVING:
        return {
          ...state,
          respIsSaving: true,
          error: null
        };
      case RESP_SAVE_SUCCESS:
        return {
          ...state,
          respSaved: true,
          respIsSaving: false,
          error: null,
          resp:action.payload
        };
      case RESP_SAVE_FAILED:
        return {
          ...state,
          respIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case RESP_IS_FETCHING:
        return { ...state,
          respIsFetching: true,
          error: null };
      case RESP_FETCH_SUCCESS:
        return {
          ...state,
          respIsFetching: false,
          resps: action.payload,
          error: null
        };
      case RESP_FETCH_FAILED:
        return {
          ...state,
          respIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case RESP_IS_UPDATING:
        return { ...state, respIsUpdating: true,error: null };
      case RESP_UPDATE_SUCCESS:
        return {
          ...state,
          respUpdated: true,
          respIsUpdating: false,
          error: null
        };
      case RESP_UPDATE_FAILED:
        return {
          ...state,
          respIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case RESP_IS_PATCHING:
        return { ...state, respIsPatching: true,error: null };
      case RESP_PATCH_SUCCESS:
        return {
          ...state,
          respPatched: true,
          respIsPatching: false,
          error: null
        };
      case RESP_PATCH_FAILED:
        return {
          ...state,
          respIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case RESP_IS_DELETING:
      return { ...state, respIsDeleting: true ,error: null};
    case RESP_DELETE_SUCCESS:
      return {
        ...state,
        respDeleted: true,
        respIsDeleting: false,
        error: null
      };
    case RESP_DELETE_FAILED:
      return {
        ...state,
        respIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default respReducer;
  
  