/*-----------Types of RESPONSABILITY actions--------*/

/*---SAVE----*/
export const RESP_SAVE_SUCCESS = "RESP_SAVE_SUCCESS";
export const RESP_SAVE_FAILED = "RESP_SAVE_FAILED";
export const RESP_IS_SAVING = "RESP_IS_SAVING";
/*---FETCH----*/
export const RESP_FETCH_SUCCESS = "RESP_FETCH_SUCCESS";
export const RESP_FETCH_FAILED = "RESP_FETCH_FAILED";
export const RESP_IS_FETCHING = "RESP_IS_FETCHING";

/*---UPDATE----*/
export const RESP_UPDATE_SUCCESS = "RESP_UPDATE_SUCCESS";
export const RESP_UPDATE_FAILED = "RESP_UPDATE_FAILED";
export const RESP_IS_UPDATING = "RESP_IS_UPDATING";

/*---PATCH----*/
export const RESP_PATCH_SUCCESS = "RESP_PATCH_SUCCESS";
export const RESP_PATCH_FAILED = "RESP_PATCH_FAILED";
export const RESP_IS_PATCHING = "RESP_IS_PATCHING";


/*---DELETE----*/
export const RESP_DELETE_SUCCESS = "RESP_DELETE_SUCCESS";
export const RESP_DELETE_FAILED = "RESP_DELETE_FAILED";
export const RESP_IS_DELETING = "RESP_IS_DELETING";