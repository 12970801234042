import {
  //SAVE
    COMMUNICATION_SAVE_FAILED,
    COMMUNICATION_SAVE_SUCCESS,
    COMMUNICATION_IS_SAVING,
    //LOAD
    COMMUNICATION_FETCH_FAILED,
    COMMUNICATION_FETCH_SUCCESS,
    COMMUNICATION_IS_FETCHING,
    //UPDATE
    COMMUNICATION_IS_UPDATING,
    COMMUNICATION_UPDATE_FAILED,
    COMMUNICATION_UPDATE_SUCCESS,
    //DELETE
    COMMUNICATION_DELETE_SUCCESS,
    COMMUNICATION_DELETE_FAILED,
    COMMUNICATION_IS_DELETING,
    //PATCH
    COMMUNICATION_IS_PATCHING,
    COMMUNICATION_PATCH_FAILED,
    COMMUNICATION_PATCH_SUCCESS
   
  } from "../types/communicationTypes";
  
  var initialState = {
    communicationSaved: false,
    communicationIsSaving: false,
    communicationIsFetching: false,
    communicationUpdated: false,
    communicationIsUpdating: false,
    communicationPatched: false,
    communicationIsPatching: false,
    communicationDeleted: false,
    communicationIsDeleting: false,
    error: null,
    communications: [],
    communication:{}
  };
  
  const communicationReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case COMMUNICATION_IS_SAVING:
        return {
          ...state,
          communicationIsSaving: true,
          error: null
        };
      case COMMUNICATION_SAVE_SUCCESS:
        return {
          ...state,
          communicationSaved: true,
          communicationIsSaving: false,
          error: null,
          communication:action.payload
        };
      case COMMUNICATION_SAVE_FAILED:
        return {
          ...state,
          communicationIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case COMMUNICATION_IS_FETCHING:
        return { ...state,
          communicationIsFetching: true,
          error: null };
      case COMMUNICATION_FETCH_SUCCESS:
        return {
          ...state,
          communicationIsFetching: false,
          communications: action.payload,
          error: null
        };
      case COMMUNICATION_FETCH_FAILED:
        return {
          ...state,
          communicationIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case COMMUNICATION_IS_UPDATING:
        return { ...state, communicationIsUpdating: true,error: null };
      case COMMUNICATION_UPDATE_SUCCESS:
        return {
          ...state,
          communicationUpdated: true,
          communicationIsUpdating: false,
          error: null
        };
      case COMMUNICATION_UPDATE_FAILED:
        return {
          ...state,
          communicationIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case COMMUNICATION_IS_PATCHING:
        return { ...state, communicationIsPatching: true,error: null };
      case COMMUNICATION_PATCH_SUCCESS:
        return {
          ...state,
          communicationPatched: true,
          communicationIsPatching: false,
          error: null
        };
      case COMMUNICATION_PATCH_FAILED:
        return {
          ...state,
          communicationIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case COMMUNICATION_IS_DELETING:
      return { ...state, communicationIsDeleting: true ,error: null};
    case COMMUNICATION_DELETE_SUCCESS:
      return {
        ...state,
        communicationDeleted: true,
        communicationIsDeleting: false,
        error: null
      };
    case COMMUNICATION_DELETE_FAILED:
      return {
        ...state,
        communicationIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default communicationReducer;
  
  