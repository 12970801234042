import {
  //SAVE
    THESIS_SAVE_FAILED,
    THESIS_SAVE_SUCCESS,
    THESIS_IS_SAVING,
    //LOAD
    THESIS_FETCH_FAILED,
    THESIS_FETCH_SUCCESS,
    THESIS_IS_FETCHING,
    //UPDATE
    THESIS_IS_UPDATING,
    THESIS_UPDATE_FAILED,
    THESIS_UPDATE_SUCCESS,
    //DELETE
    THESIS_DELETE_SUCCESS,
    THESIS_DELETE_FAILED,
    THESIS_IS_DELETING,
    //PATCH
    THESIS_IS_PATCHING,
    THESIS_PATCH_FAILED,
    THESIS_PATCH_SUCCESS
   
  } from "../types/thesisTypes";
  
  var initialState = {
    thesisSaved: false,
    thesisIsSaving: false,
    thesisIsFetching: false,
    thesisUpdated: false,
    thesisIsUpdating: false,
    thesisPatched: false,
    thesisIsPatching: false,
    thesisDeleted: false,
    thesisIsDeleting: false,
    error: null,
    thesiss: [],
    thesis:{}
  };
  
  const thesisReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case THESIS_IS_SAVING:
        return {
          ...state,
          thesisIsSaving: true,
          error: null
        };
      case THESIS_SAVE_SUCCESS:
        return {
          ...state,
          thesisSaved: true,
          thesisIsSaving: false,
          error: null,
          thesis:action.payload
        };
      case THESIS_SAVE_FAILED:
        return {
          ...state,
          thesisIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case THESIS_IS_FETCHING:
        return { ...state,
          thesisIsFetching: true,
          error: null };
      case THESIS_FETCH_SUCCESS:
        return {
          ...state,
          thesisIsFetching: false,
          thesiss: action.payload,
          error: null
        };
      case THESIS_FETCH_FAILED:
        return {
          ...state,
          thesisIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case THESIS_IS_UPDATING:
        return { ...state, thesisIsUpdating: true,error: null };
      case THESIS_UPDATE_SUCCESS:
        return {
          ...state,
          thesisUpdated: true,
          thesisIsUpdating: false,
          error: null
        };
      case THESIS_UPDATE_FAILED:
        return {
          ...state,
          thesisIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case THESIS_IS_PATCHING:
        return { ...state, thesisIsPatching: true,error: null };
      case THESIS_PATCH_SUCCESS:
        return {
          ...state,
          thesisPatched: true,
          thesisIsPatching: false,
          error: null
        };
      case THESIS_PATCH_FAILED:
        return {
          ...state,
          thesisIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case THESIS_IS_DELETING:
      return { ...state, thesisIsDeleting: true ,error: null};
    case THESIS_DELETE_SUCCESS:
      return {
        ...state,
        thesisDeleted: true,
        thesisIsDeleting: false,
        error: null
      };
    case THESIS_DELETE_FAILED:
      return {
        ...state,
        thesisIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default thesisReducer;
  
  