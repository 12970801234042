/*-----------Types of PROGRAM actions--------*/

/*---SAVE----*/
export const PROGRAM_SAVE_SUCCESS = "PROGRAM_SAVE_SUCCESS";
export const PROGRAM_SAVE_FAILED = "PROGRAM_SAVE_FAILED";
export const PROGRAM_IS_SAVING = "PROGRAM_IS_SAVING";
/*---FETCH----*/
export const PROGRAM_FETCH_SUCCESS = "PROGRAM_FETCH_SUCCESS";
export const ONE_PROGRAM_FETCH_SUCCESS = "One_PROGRAM_FETCH_SUCCESS";
export const PROGRAM_FETCH_FAILED = "PROGRAM_FETCH_FAILED";
export const PROGRAM_IS_FETCHING = "PROGRAM_IS_FETCHING";
/*---UPDATE----*/
export const PROGRAM_UPDATE_SUCCESS = "PROGRAM_UPDATE_SUCCESS";
export const PROGRAM_UPDATE_FAILED = "PROGRAM_UPDATE_FAILED";
export const PROGRAM_IS_UPDATING = "PROGRAM_IS_UPDATING";

/*---PATCH----*/
export const PROGRAM_PATCH_SUCCESS = "PROGRAM_PATCH_SUCCESS";
export const PROGRAM_PATCH_FAILED = "PROGRAM_PATCH_FAILED";
export const PROGRAM_IS_PATCHING = "PROGRAM_IS_PATCHING";


/*---DELETE----*/
export const PROGRAM_DELETE_SUCCESS = "PROGRAM_DELETE_SUCCESS";
export const PROGRAM_DELETE_FAILED = "PROGRAM_DELETE_FAILED";
export const PROGRAM_IS_DELETING = "PROGRAM_IS_DELETING";