import {
  //SAVE
    SEMINAR_SAVE_FAILED,
    SEMINAR_SAVE_SUCCESS,
    SEMINAR_IS_SAVING,
    //LOAD
    SEMINAR_FETCH_FAILED,
    SEMINAR_FETCH_SUCCESS,
    SEMINAR_IS_FETCHING,
    //UPDATE
    SEMINAR_IS_UPDATING,
    SEMINAR_UPDATE_FAILED,
    SEMINAR_UPDATE_SUCCESS,
    //DELETE
    SEMINAR_DELETE_SUCCESS,
    SEMINAR_DELETE_FAILED,
    SEMINAR_IS_DELETING,
    //PATCH
    SEMINAR_IS_PATCHING,
    SEMINAR_PATCH_FAILED,
    SEMINAR_PATCH_SUCCESS
   
  } from "../types/seminarTypes";
  
  var initialState = {
    seminarSaved: false,
    seminarIsSaving: false,
    seminarIsFetching: false,
    seminarUpdated: false,
    seminarIsUpdating: false,
    seminarPatched: false,
    seminarIsPatching: false,
    seminarDeleted: false,
    seminarIsDeleting: false,
    error: null,
    seminars: [],
    seminar:{}
  };
  
  const seminarReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case SEMINAR_IS_SAVING:
        return {
          ...state,
          seminarIsSaving: true,
          error: null
        };
      case SEMINAR_SAVE_SUCCESS:
        return {
          ...state,
          seminarSaved: true,
          seminarIsSaving: false,
          error: null,
          seminar:action.payload
        };
      case SEMINAR_SAVE_FAILED:
        return {
          ...state,
          seminarIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case SEMINAR_IS_FETCHING:
        return { ...state,
          seminarIsFetching: true,
          error: null };
      case SEMINAR_FETCH_SUCCESS:
        return {
          ...state,
          seminarIsFetching: false,
          seminars: action.payload,
          error: null
        };
      case SEMINAR_FETCH_FAILED:
        return {
          ...state,
          seminarIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case SEMINAR_IS_UPDATING:
        return { ...state, seminarIsUpdating: true,error: null };
      case SEMINAR_UPDATE_SUCCESS:
        return {
          ...state,
          seminarUpdated: true,
          seminarIsUpdating: false,
          error: null
        };
      case SEMINAR_UPDATE_FAILED:
        return {
          ...state,
          seminarIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case SEMINAR_IS_PATCHING:
        return { ...state, seminarIsPatching: true,error: null };
      case SEMINAR_PATCH_SUCCESS:
        return {
          ...state,
          seminarPatched: true,
          seminarIsPatching: false,
          error: null
        };
      case SEMINAR_PATCH_FAILED:
        return {
          ...state,
          seminarIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case SEMINAR_IS_DELETING:
      return { ...state, seminarIsDeleting: true ,error: null};
    case SEMINAR_DELETE_SUCCESS:
      return {
        ...state,
        seminarDeleted: true,
        seminarIsDeleting: false,
        error: null
      };
    case SEMINAR_DELETE_FAILED:
      return {
        ...state,
        seminarIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default seminarReducer;
  
  